import React, { Component } from 'react'

class Aqaba extends Component {
  render() {
    return (
      <div className="aqaba-page">
        <h2>More details about this magnificent event will be available soon.</h2>
      </div>
    )
  }
}

export default Aqaba;